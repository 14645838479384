import React, { useEffect } from 'react';
import './HRIS.css'; // Make sure to create and import the CSS file
import HRISLogo from './HRISLogo1.png';
import DBESTLogo from './DBESTLogo2.png';
import MISLogo from './MISLogo1.png';
import ErankLogo from './ErankLogo.png'; // Import the eRank logo

const HRIS = () => {
  useEffect(() => {
    let currentSlide = 1;
    let autoplay;
    const totalSlides = document.querySelectorAll('.flex__container').length;
    const intervalTime = 8500; // 8.5 seconds interval

    const changeSlide = (next) => {
      const currentElement = document.querySelector('.flex--active');
      const nextElement = document.querySelector(`.flex__container[data-slide="${next}"]`);

      if (currentElement && nextElement) {
        const current = currentElement.dataset.slide;

        document.querySelectorAll('.slide-nav').forEach((nav) => nav.classList.remove('active'));
        document.querySelector(`.slide-nav[data-slide="${next}"]`).classList.add('active');

        if (current === next.toString()) {
          return false;
        } else {
          nextElement.classList.add('flex--preStart');
          currentElement.classList.add('animate--end');
          setTimeout(() => {
            nextElement.classList.remove('animate--start', 'flex--preStart');
            nextElement.classList.add('flex--active');
            currentElement.classList.add('animate--start');
            currentElement.classList.remove('animate--end', 'flex--active');
          }, 800);
        }
        currentSlide = next;
      }
    };

    const startAutoplay = () => {
      autoplay = setInterval(() => {
        let nextSlide = currentSlide + 1 > totalSlides ? 1 : currentSlide + 1;
        changeSlide(nextSlide);
      }, intervalTime);
    };

    const handleNavClick = (e) => {
      e.preventDefault();
      clearInterval(autoplay); // Stop autoplay when manual navigation is used
      let nextSlide = parseInt(e.currentTarget.dataset.slide);
      changeSlide(nextSlide);
      startAutoplay(); // Restart autoplay after manual navigation
    };

    document.querySelectorAll('.slide-nav').forEach((nav) => {
      nav.addEventListener('click', handleNavClick);
    });

    const handleMouseEnter = () => {
      clearInterval(autoplay);
    };

    const handleMouseLeave = () => {
      startAutoplay();
    };

    document.querySelectorAll('.flex__item--left').forEach((item) => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });

    startAutoplay();

    return () => {
      clearInterval(autoplay);
      document.querySelectorAll('.slide-nav').forEach((nav) => {
        nav.removeEventListener('click', handleNavClick);
      });
      document.querySelectorAll('.flex__item--left').forEach((item) => {
        item.removeEventListener('mouseenter', handleMouseEnter);
        item.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return (
    <section id="hris">
      <div className="slider__warpper">
        {/* Slide 1 - HRIS */}
        <div className="flex__container flex--hris flex--active" data-slide="1">
          <div className="flex__item flex__item--left">
            <div className="flex__content">
              <p className="text--sub">Human Resource Information System</p>
              <div className="logo-container">
                <img src={HRISLogo} alt="HRIS" className="logo-image" />
              </div>
              <p className="text--normal">
                HRIS is designed to streamline the management of employee personal data, teacher assignments, and document tracking, ensuring accurate and up-to-date records for all educational staff.
              </p>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://suresasur.online:9443/" target="_blank" className="btn">
                <span className="btn__circle"></span>
                <span className="btn__white-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" id="icon-arrow-right" viewBox="0 0 21 12">
                    <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                  </svg>
                </span>
                <span className="btn__text">VISIT HRIS</span>
              </a>
            </div>
            <p className="text__background">HRIS</p>
          </div>
          <div className="flex__item flex__item--right"></div>
        </div>

        {/* Slide 2 - DBEST */}
        <div className="flex__container flex--dbest animate--start" data-slide="2">
          <div className="flex__item flex__item--left">
            <div className="flex__content">
              <p className="text--sub">Dynamic Back-End School Tracker</p>
              <div className="logo-container">
                <img src={DBESTLogo} alt="DBEST" className="logo-image" />
              </div>
              <p className="text--normal">
                DBEST is a comprehensive tool that manages critical school data, including school profiles, enrollment figures, employee counts, class numbers, and the submission of school forms, facilitating efficient employee management at the school level.
              </p>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://suresasur.online:8443/" target="_blank" className="btn">
                <span className="btn__circle"></span>
                <span className="btn__white-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" id="icon-arrow-right" viewBox="0 0 21 12">
                    <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                  </svg>
                </span>
                <span className="btn__text">VISIT DBEST</span>
              </a>
            </div>
            <p className="text__background">DBEST</p>
          </div>
          <div className="flex__item flex__item--right"></div>
        </div>

        {/* Slide 3 - MIS */}
        <div className="flex__container flex--mis animate--start" data-slide="3">
          <div className="flex__item flex__item--left">
            <div className="flex__content">
              <p className="text--sub">Management Information System</p>
              <div className="logo-container">
                <img src={MISLogo} alt="MIS" className="logo-image" />
              </div>
              <p className="text--normal">
                MIS provides management systems tailored for different offices within the organization, supporting administrative functions and enhancing decision-making processes.
              </p>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://suresasur.online:6443/" target="_blank" className="btn">
                <span className="btn__circle"></span>
                <span className="btn__white-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" id="icon-arrow-right" viewBox="0 0 21 12">
                    <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                  </svg>
                </span>
                <span className="btn__text">VISIT MIS</span>
              </a>
            </div>
            <p className="text__background">MIS</p>
          </div>
          <div className="flex__item flex__item--right"></div>
        </div>

        {/* Slide 4 - eRank */}
        <div className="flex__container flex--erank animate--start" data-slide="4">
          <div className="flex__item flex__item--left">
            <div className="flex__content">
              <p className="text--sub">Electronic Ranking System for Teaching and Nonteaching Applicants</p>
              <div className="logo-container">
                <img src={ErankLogo} alt="eRank" className="logo-image" />
              </div>
              <p className="text--normal">
                eRank is an electronic ranking system designed to automate and streamline the evaluation and ranking process for job applicants. With eRank, the evaluation process becomes more accurate, efficient, and transparent—ensuring a fair and reliable ranking of teaching and non-teaching applicants.
              </p>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://suresasur.online:5443/" target="_blank" className="btn">
                <span className="btn__circle"></span>
                <span className="btn__white-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" id="icon-arrow-right" viewBox="0 0 21 12">
                    <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                  </svg>
                </span>
                <span className="btn__text">VISIT eRank</span>
              </a>
            </div>
            <p className="text__background">eRank</p>
          </div>
          <div className="flex__item flex__item--right"></div>
        </div>
      </div>

      <div className="slider__navi">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="#" className="slide-nav active" data-slide="1">HRIS</a>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="#" className="slide-nav" data-slide="2">DBEST</a>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="#" className="slide-nav" data-slide="3">MIS</a>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="#" className="slide-nav" data-slide="4">eRank</a>
      </div>
    </section>
  );
};

export default HRIS;
