import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Track.css'; // Import the CSS file
import { BASE_URL } from '../Utility/ApiConfig';
import InlineError from '../components/ErrorPage/InlineError';

const Track = ({ searchValue }) => {
  const h2Ref = useRef(null);
  const trackSectionRef = useRef(null);

  const [trackData, setTrackData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [docTrackNumWrd, setDocTrackNumWrd] = useState(searchValue || '');
  const inputRef = useRef(null);

  // const fetchTrackData = async (trackNumber) => {
  //   setLoading(true); // Start loading
  //   try {
  //     const response = await fetch(
  //       `${BASE_URL}/Track/GetTrackStatusByDocTrackNumWrd/${trackNumber}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer YI5YN3tmlcR2pEDBT68Y8WaafGX-79KmIxHEp8rJ`,
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`Request failed with status: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     if (data.message === 'No track status found.' || data.length === 0) {
  //       setError('No tracking data found for the entered tracking number.');
  //       setShowTable(false);
  //       setShowSearch(false);
  //     } else {
  //       setTrackData(data);
  //       setShowTable(true);
  //       setShowSearch(false);
  //       setError(null);
  //     }
  //   } catch (fetchError) {
  //     setError(`Error fetching tracking data: ${fetchError.message}`);
  //     setShowTable(false);
  //     setShowSearch(false);
  //   } finally {
  //     setLoading(false); // End loading
  //   }
  // };
  const fetchTrackData = async (trackNumber) => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(
        `${BASE_URL}/Track/GetTrackStatusByDocTrackNumWrd/${trackNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer YI5YN3tmlcR2pEDBT68Y8WaafGX-79KmIxHEp8rJ`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      const data = await response.json();
  
      // Simulate delay to allow the animation to complete
      setTimeout(() => {
        if (data.message === 'No track status found.' || data.length === 0) {
          setError('No tracking data found for the entered tracking number.');
          setShowTable(false);
          setShowSearch(false);
        } else {
          setTrackData(data);
          setShowTable(true);
          setShowSearch(false);
          setError(null);
        }
        setLoading(false); // End loading
      }, 5000); // Ensure this matches your --duration variable
    } catch (fetchError) {
      setTimeout(() => {
        setError(`Error fetching tracking data: ${fetchError.message}`);
        setShowTable(false);
        setShowSearch(false);
        setLoading(false); // End loading
      }, 5000);
    }
  };
  
  const handleSearch = (e) => {
    if (e.key === 'Enter' && docTrackNumWrd) {
      fetchTrackData(docTrackNumWrd);
    }
  };

  const handleSearchClick = () => {
    if (docTrackNumWrd) {
      fetchTrackData(docTrackNumWrd);
    }
  };

  useEffect(() => {
    if (searchValue) {
      setDocTrackNumWrd(searchValue);
      fetchTrackData(searchValue);
    }
  }, [searchValue]);

  const getStatusClass = (status) => {
    switch (status) {
      case 'Received':
        return 'bg-green-400 text-gray-50 rounded-md px-2';
      case 'Forwarded':
        return 'bg-yellow-600 text-gray-50 rounded-md px-2';
      case 'Released':
        return 'bg-red-400 text-gray-50 rounded-md px-2';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!h2Ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          motion(h2Ref.current, { opacity: 1, y: 0 }, { duration: 1 });
        } else {
          motion(h2Ref.current, { opacity: 0, y: -50 }, { duration: 1 });
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(h2Ref.current);

    return () => {
      if (h2Ref.current) {
        // eslint-disable-next-line
        observer.unobserve(h2Ref.current);
      }
    };
  }, [h2Ref]);

  const handleMouseOver = () => {
    const bubbles = document.querySelectorAll('.bubble');
    bubbles.forEach((bubble, index) => {
      bubble.style.left = `${index * 50}px`;
      bubble.style.width = index === 0 ? '350px' : '50px';
      bubble.style.borderRadius = index === 0 ? '10px' : '50%';
    });
    inputRef.current.style.display = 'block';
    inputRef.current.focus();
  };

  const handleMouseOut = () => {
    const bubbles = document.querySelectorAll('.bubble');
    bubbles.forEach((bubble, index) => {
      bubble.style.left = `${index * 50}px`;
      bubble.style.width = '50px';
      bubble.style.borderRadius = '50%';
    });
    inputRef.current.style.display = 'none';
  };

  const handleCloseClick = () => {
    setShowTable(false);
    setShowSearch(true);
    setError(null);
    setDocTrackNumWrd('');
  };

  useEffect(() => {
    const bubbling = () => {
      const bubbles = document.querySelectorAll('.bubble');
      let counter = 0;
      const animateBubbles = () => {
        if (counter < bubbles.length) {
          setTimeout(() => {
            bubbles[counter].classList.add('animate');
            counter++;
            animateBubbles();
          }, 80);
        }
      };
      animateBubbles();
    };

    bubbling();
  }, []);

  const handleInput = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setDocTrackNumWrd(value);
    }
  };

  const handleGoBack = () => {
    setShowSearch(true);
    setError(null);
    setDocTrackNumWrd('');

    if (trackSectionRef.current) {
      trackSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const triggerSearchAnimation = () => {
    const bubbles = document.querySelectorAll('.bubble');
    let counter = 0;
    const animateBubbles = () => {
      if (counter < bubbles.length) {
        setTimeout(() => {
          bubbles[counter].classList.add('animate');
          counter++;
          animateBubbles();
        }, 80);
      }
    };
    animateBubbles();
  };

  useEffect(() => {
    if (showSearch) {
      triggerSearchAnimation();
    }
  }, [showSearch]);

  // Pagination logic
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = trackData.slice(indexOfFirstResult, indexOfLastResult);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section
      id="track"
      className="track-section flex items-center justify-center min-h-screen bg-gray-900 m-0 p-0"
      ref={trackSectionRef}
    >
      <div className="top-right"></div>
      <div className="bottom-left"></div>
      <div className="col-span-12">
        <motion.h2
          ref={h2Ref}
          style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'white' }}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Document Tracking System
        </motion.h2>
        {loading ? (
          <>
            <h1 className="title">Loading</h1>
            <div className="rainbow-marker-loader"></div>
          </>
        ) : (
          <>
            {showSearch && (
              <>
                <div className="container">
                  <div
                    className="search-wrapper"
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <div id="searchBox">
                      <div className="bubble">
                        <p className="S" style={{ color: '#4C83F0' }}>
                          S
                        </p>
                      </div>
                      <div className="bubble" style={{ left: '50px' }}>
                        <p style={{ color: '#D1333B' }}>E</p>
                      </div>
                      <div className="bubble" style={{ left: '100px' }}>
                        <p style={{ color: '#EEB80B' }}>A</p>
                      </div>
                      <div className="bubble" style={{ left: '150px' }}>
                        <p style={{ color: '#4C83F0' }}>R</p>
                      </div>
                      <div className="bubble" style={{ left: '200px' }}>
                        <p style={{ color: '#1CAF60' }}>C</p>
                      </div>
                      <div className="bubble" style={{ left: '250px' }}>
                        <p style={{ color: '#D1333B' }}>H</p>
                      </div>
                      <div
                        className="bubble"
                        style={{ left: '300px' }}
                        onClick={handleSearchClick}
                      >
                        <p>🔎</p>
                      </div>
                      <input
                        ref={inputRef}
                        type="text"
                        className="inputSearch"
                        placeholder="Enter your tracking number here."
                        value={docTrackNumWrd}
                        onChange={handleInput}
                        onKeyPress={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {showTable && trackData.length > 0 && (
              <div className="table-container relative">
                <div className="close-button" onClick={handleCloseClick}>
                  <div className="outer">
                    <div className="inner">
                      <label>Close</label>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto lg:overflow-visible">
                  <table className="table text-gray-400 border-separate space-y-6 text-sm">
                    <thead className="bg-gray-800 text-gray-500">
                      <tr>
                        <th className="p-3">Action Date and Time</th>
                        <th className="p-3 text-left">Action Taken</th>
                        <th className="p-3 text-left">Receiving Office</th>
                        <th className="p-3 text-left">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentResults.map((data, index) => (
                        <tr className="bg-gray-800" key={index}>
                          <td className="p-3">
                            {new Date(data.statDateTime).toLocaleString()}
                          </td>
                          <td className="p-3">
                            <span className={getStatusClass(data.statAction)}>
                              {data.statAction}
                            </span>
                          </td>
                          <td className="p-3">{data.offName}</td>
                          <td className="p-3">{data.statRemarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <li>
                    <button
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                  </li>
                  {[...Array(Math.ceil(trackData.length / resultsPerPage)).keys()].map(
                    (number) => (
                      <li
                        key={number + 1}
                        className={currentPage === number + 1 ? 'active' : ''}
                      >
                        <button onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </button>
                      </li>
                    )
                  )}
                  <li>
                    <button
                      onClick={() => paginate(currentPage + 1)}
                      disabled={currentPage === Math.ceil(trackData.length / resultsPerPage)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            )}
            {error && <InlineError onGoBack={handleGoBack} />}
          </>
        )}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ display: 'none' }}>
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </section>
  );
};

export default Track;
