import React, { useEffect, useState } from 'react';
import './Home.css';
import Card from '../components/Card/Card';
import UISLogo from '../components/UISLOGO3.png'; // Updated path to the uploaded image
import CentralizedDatabase from '../components/CardImage/Centralized-Database.svg';
import EnhancedCollaboration from '../components/CardImage/Enhanced-Collaboration.svg';
import ImprovedDataManagement from '../components/CardImage/Improved-Data-Management.svg';
import OperationalEfficiency from '../components/CardImage/Operational-Efficiency.svg';
import ScalabilityFlexibility from '../components/CardImage/Scalability-Flexibility.svg';

const Home = () => {
  const [isStacked, setIsStacked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 448 && window.innerHeight < 850) {
        setIsStacked(true);
      } else {
        setIsStacked(false);
      }
    };

    window.addEventListener('resize', handleResize);
 
    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCardClick = (e) => {
    if (!isStacked) return;
    const card = document.querySelector('.nft:last-child');
    if (e.target.closest('.nft') !== card) return;
    card.style.animation = 'swap 700ms forwards';
    
    setTimeout(() => {
      card.style.animation = '';
      document.querySelector('.card-container').prepend(card);
    }, 700);
  };

  return (
    <section id="home">
      <div className="logo-card" style={{ "--color": "hsl(357, 100%, 49%)" }}>
        <div className="card__border"></div>
        <div className="card__border-line"></div>
        <div className="card__inner">
          <div className="card__img">
            <img src={UISLogo} alt="UIS logo" className="uis-logo" />
          </div>
          <div className="card__text">
            <div></div>
            <h1 className="name"></h1>
            <p className="points"></p>
          </div>
        </div>
        <svg className="card__circuit-left" viewBox="0 0 127 127" xmlns="http://www.w3.org/2000/svg">
          <g className="static-path" stroke="#FFAE0C" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="1 0 27 0 55 28 95 28 115 48 127 48"></polyline>
            <polyline points="1 23 27 23 47 43 86 43 107 64 127 64"></polyline>
            <polyline points="0 10.9791757 26.9791757 10.9791757 51 35 90 35 111 56 127 56"></polyline>
            <polyline points="1 92 98 92 110 80 127 80"></polyline>
            <polyline points="1 80 99 80 107 72 127 72"></polyline>
          </g>
          <g className="animated-path path" stroke="#FFAE0C" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="30,150" strokeDashoffset="2000">
            <polyline points="1 0 27 0 55 28 95 28 115 48 127 48"></polyline>
            <polyline points="1 23 27 23 47 43 86 43 107 64 127 64"></polyline>
            <polyline points="0 10.9791757 26.9791757 10.9791757 51 35 90 35 111 56 127 56"></polyline>
            <polyline points="1 92 98 92 110 80 127 80"></polyline>
            <polyline points="1 80 99 80 107 72 127 72"></polyline>
          </g>
        </svg>
        <svg className="card__circuit-bottom" viewBox="0 0 48 67" xmlns="http://www.w3.org/2000/svg">
          <g className="static-path" stroke="#FFAE0C" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="4.02077033 67.0000542 4.02077033 63.0208243 12.0415946 55 12.0415946 40.0415946 0 28 0 0"></polyline>
            <polyline points="20 67 20 36 8 24 8 0"></polyline>
            <polyline points="27 63 27 31 16 20 16 0"></polyline>
            <polyline points="36 67 36 28 24 16 24 0"></polyline>
            <polyline points="48 62 48 48 44 44 44 23 32 11 32 0"></polyline>
          </g>
          <g className="animated-path path" stroke="#FFAE0C" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="30,150" strokeDashoffset="2000">
            <polyline points="4.02077033 67.0000542 4.02077033 63.0208243 12.0415946 55 12.0415946 40.0415946 0 28 0 0"></polyline>
            <polyline points="20 67 20 36 8 24 8 0"></polyline>
            <polyline points="27 63 27 31 16 20 16 0"></polyline>
            <polyline points="36 67 36 28 24 16 24 0"></polyline>
            <polyline points="48 62 48 48 44 44 44 23 32 11 32 0"></polyline>
          </g>
          <g className="circle-animations">
            <circle cx="4" cy="63 " r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="20" cy="63" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="27" cy="63" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="36" cy="63" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="48" cy="63" r="4" fill="#FFAE0C" className="animated-circle"></circle>
          </g>
        </svg>
        <svg className="card__circuit-right" viewBox="0 0 152 72" xmlns="http://www.w3.org/2000/svg">
          <g className="static-path" stroke="#FFAE0C" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="16 69 28 69 44 85 52 85 56 81 68 81"></polyline>
            <polyline points="16 61 100 61 104 65 111 65"></polyline>
            <polyline points="16 53 120 53 132.041595 65.0415946 132.041595 72.0001243"></polyline>
            <polyline points="16 45 124.00463 45 141.002315 61.9976854"></polyline>
            <polyline points="16 37 128 37 144 53 152 53"></polyline>
            <polyline points="8 28 8 21 17 12 44 12 60 28 131 28 140 37 152 37"></polyline>
            <polyline points="0 28 0 16 16 0 22 0"></polyline>
          </g>
          <g className="animated-path path-2" stroke="#FFAE0C" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="50,150" strokeDashoffset="2000">
            <polyline points="16 69 28 69 44 85 52 85 56 81 68 81"></polyline>
            <polyline points="16 61 100 61 104 65 111 65"></polyline>
            <polyline points="16 53 120 53 132.041595 65.0415946 132.041595 72.0001243"></polyline>
            <polyline points="16 45 124.00463 45 141.002315 61.9976854"></polyline>
            <polyline points="16 37 128 37 144 53 152 53"></polyline>
            <polyline points="8 28 8 21 17 12 44 12 60 28 131 28 140 37 152 37"></polyline>
            <polyline points="0 28 0 16 16 0 22 0"></polyline>
          </g>
          <g className="circle-animations">
            <circle cx="68" cy="81" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="111" cy="65" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="132" cy="72" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="141" cy="62" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="148" cy="53" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="147" cy="37" r="4" fill="#FFAE0C" className="animated-circle"></circle>
            <circle cx="22" cy="0" r="4" fill="#FFAE0C" className="animated-circle"></circle>
          </g>
        </svg>
      </div>

      <div className={`card-container ${isStacked ? 'stacked' : ''}`} onClick={handleCardClick}>
        <Card
          imageSrc={CentralizedDatabase}
          title="Centralized Database"
          description="Provides a single source of truth for all organizational data, reducing redundancy and ensuring data consistency across offices. Facilitates quick access to critical information, enhancing decision-making and strategic planning."
        />
        <Card
          imageSrc={OperationalEfficiency}
          title="Operational Efficiency"
          description="Streamlines processes, automates routine tasks, reducing manual workload and minimizing human errors."
        />
        <Card
          imageSrc={EnhancedCollaboration}
          title="Enhanced Collaboration"
          description="Promotes seamless communication and coordination across various offices and units within the schools division. Enables real-time sharing of information, fostering teamwork and collective problem-solving."
        />
        <Card
          imageSrc={ImprovedDataManagement}
          title="Improved Data Management"
          description="Ensures accurate and up-to-date records for all functions, supporting compliance with regulatory requirements. Enhances data security and integrity through centralized control and management of information."
        />
        <Card
          imageSrc={ScalabilityFlexibility}
          title="Scalability and Flexibility"
          description="Designed to accommodate the growing needs of the organization, with the ability to scale and integrate new modules as required."
        />
      </div>
    </section>
  );
};

export default Home;
