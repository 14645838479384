import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css';
import { ReactComponent as ZamboSurLogo } from './zambo sur-min (2).svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Navbar({ onSearch }) {
  const [click, setClick] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [activeSlide, setActiveSlide] = useState(0); // New state to track active slide

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    let current = '';

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 50;
      const sectionHeight = section.clientHeight;
      if (window.scrollY >= sectionTop && window.scrollY < sectionTop + sectionHeight) {
        current = section.getAttribute('id');
      }
    });

    setActiveSection(current);

    if (window.scrollY > 50) {
      setNavbarVisible(true);
    } else {
      setNavbarVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchClick = () => {
    if (searchValue) {
      onSearch(searchValue);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setSearchValue(value);
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const scrollToSlide = (slideIndex) => {
    const section = document.getElementById('hris');
    const sliderWrapper = document.querySelector('.slider__warpper');

    if (section && sliderWrapper) {
      window.scrollTo({
        top: section.offsetTop - 50,
        behavior: 'smooth',
      });

      // Manually change the slide
      const changeSlide = (next) => {
        const currentElement = sliderWrapper.querySelector('.flex--active');
        const nextElement = sliderWrapper.querySelector(`.flex__container[data-slide="${next}"]`);

        // Add check to ensure elements are found before using them
        if (currentElement && nextElement) {
          const current = currentElement.dataset.slide;

          sliderWrapper.querySelectorAll('.slide-nav').forEach((nav) => nav.classList.remove('active'));
          const navToActivate = sliderWrapper.querySelector(`.slide-nav[data-slide="${next}"]`);
          if (navToActivate) {
            navToActivate.classList.add('active');
          }

          if (current === next.toString()) {
            return false;
          } else {
            nextElement.classList.add('flex--preStart');
            currentElement.classList.add('animate--end');
            setTimeout(() => {
              nextElement.classList.remove('animate--start', 'flex--preStart');
              nextElement.classList.add('flex--active');
              currentElement.classList.add('animate--start');
              currentElement.classList.remove('animate--end', 'flex--active');
              setActiveSlide(next); // Update active slide
            }, 800);
          }
        } else {
          console.error('Slide elements not found:', { currentElement, nextElement });
        }
      };

      changeSlide(slideIndex);
    } else {
      console.error("Section or slider wrapper not found.");
    }
  };

  return (
    <>
      <nav id="navbar" className={`navbar ${navbarVisible ? 'hidden' : ''}`}>
        <div className="navbar-container">
          <ZamboSurLogo className="logo-svg" />
          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={click ? faTimes : faBars} />
          </div>
          <ul className={click ? 'nav-links active' : 'nav-links'}>
            <li className="nav-item">
              <ScrollLink
                to="home"
                className={`nav-link ${activeSection === 'home' ? 'active' : ''}`}
                smooth={true}
                duration={1000}
                onClick={closeMobileMenu}
              >
                Home
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                to="hris"
                className={`nav-link ${activeSection === 'hris' && activeSlide === 1 ? 'active' : ''}`}
                smooth={true}
                duration={1000}
                onClick={() => {
                  scrollToSlide(1);
                  closeMobileMenu();
                }}
              >
                HRIS
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                to="hris"
                className={`nav-link ${activeSection === 'hris' && activeSlide === 2 ? 'active' : ''}`}
                smooth={true}
                duration={1000}
                onClick={() => {
                  scrollToSlide(2);
                  closeMobileMenu();
                }}
              >
                DBEST
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                to="hris"
                className={`nav-link ${activeSection === 'hris' && activeSlide === 3 ? 'active' : ''}`}
                smooth={true}
                duration={1000}
                onClick={() => {
                  scrollToSlide(3);
                  closeMobileMenu();
                }}
              >
                MIS
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                to="hris"
                className={`nav-link ${activeSection === 'hris' && activeSlide === 4 ? 'active' : ''}`}
                smooth={true}
                duration={1000}
                onClick={() => {
                  scrollToSlide(4);
                  closeMobileMenu();
                }}
              >
                eRank
              </ScrollLink>
            </li>
          </ul>
          <div className="search-container">
            <button className="search-icon" onClick={handleSearchClick}>
              <svg
                aria-hidden="true"
                height="16"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                data-view-component="true"
                className="octicon octicon-search"
              >
                <path d="M10.68 11.74a6 6 0 0 1-7.922-8.982 6 6 0 0 1 8.982 7.922l3.04 3.04a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215ZM11.5 7a4.499 4.499 0 1 0-8.997 0A4.499 4.499 0 0 0 11.5 7Z"></path>
              </svg>
            </button>
            <input
              type="text"
              placeholder="Enter tracking number here"
              className="search-input"
              value={searchValue}
              onChange={handleSearchChange}
              onKeyPress={handleSearchKeyPress}
            />
            <div className="hotkey-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" aria-hidden="true" className="mr-1">
                <path
                  fill="none"
                  stroke="#979A9C"
                  opacity=".4"
                  d="M3.5.5h12c1.7 0 3 1.3 3 3v13c0 1.7-1.3 3-3 3h-12c-1.7 0-3-1.3-3-3v-13c0-1.7 1.3-3 3-3z"
                ></path>
                <path fill="#979A9C" d="M11.8 6L8 15.1h-.9L10.8 6h1z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>

      <nav id="scrolled-navbar" className={`navbar scrolled-navbar ${navbarVisible ? 'visible' : ''}`}>
        <ul className="nav-links">
          <li className="nav-item">
            <ScrollLink
              to="home"
              className={`nav-link ${activeSection === 'home' ? 'active' : ''}`}
              smooth={true}
              duration={1000}
              onClick={closeMobileMenu}
            >
              Home
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="hris"
              className={`nav-link ${activeSection === 'hris' && activeSlide === 1 ? 'active' : ''}`}
              smooth={true}
              duration={1000}
              onClick={() => {
                scrollToSlide(1);
                closeMobileMenu();
              }}
            >
              HRIS
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="hris"
              className={`nav-link ${activeSection === 'hris' && activeSlide === 2 ? 'active' : ''}`}
              smooth={true}
              duration={1000}
              onClick={() => {
                scrollToSlide(2);
                closeMobileMenu();
              }}
            >
              DBEST
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="hris"
              className={`nav-link ${activeSection === 'hris' && activeSlide === 3 ? 'active' : ''}`}
              smooth={true}
              duration={1000}
              onClick={() => {
                scrollToSlide(3);
                closeMobileMenu();
              }}
            >
              MIS
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="hris"
              className={`nav-link ${activeSection === 'hris' && activeSlide === 4 ? 'active' : ''}`}
              smooth={true}
              duration={1000}
              onClick={() => {
                scrollToSlide(4);
                closeMobileMenu();
              }}
            >
              eRank
            </ScrollLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
